<template>
  <cui-acl  :roles="['underDevelopment']" redirect>
    <h2>Min Lønn</h2>
    <!-- Display payslips here -->
    <a-spin :spinning="loading">
      <payslip-list :payslips="payslips"/>
    </a-spin>
  </cui-acl>
</template>

<script>
import { firestore } from '@/services/firebase'
import cuiAcl from '@/components/cleanui/system/ACL'
import PayslipList from '@/components/custom/payroll/payslipsList'
import moment from 'moment'

export default {
  components: {
    PayslipList,
    cuiAcl,
  },
  data() {
    return {
      period: null,
      loading: false,
      employeeIDs: [],
      users: [], // Fetch employees from Firestore
      departments: [], // Fetch departments from Firestore
      selectedEmployee: null,
      selectedDepartment: null,
      payslips: [], // Fetch payslips from Firestore
    }
  },
  methods: {
    handleFilterChange() {
      const period = this.period ? String(moment(this.period).format('YYYYMM')) : null
      this.period = period
      this.getPayslips()
      // Fetch payslips based on the selected period
    },
    getPayslips() {
      this.loading = true
      this.paylips = []
      this.users = []

      // Fetch payslips from Firestore
      console.log(this.$store.state.user.employeeID)
      const query = firestore.collection('payslips').where('employeeID', '==', this.$store.state.user.employeeID)
      query
        .get().then(async (querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const payslip = doc.data()
            // payslip.employee = this.employees.find(employee => employee.id === payslip.employeeID)
            // payslip.title = `${payslip.employeeID} - ${moment(payslip.period).format('MMMM YYYY')}`
            payslip.id = doc.id
            this.payslips.push(payslip)
            // store unique employee IDs
            if (!this.employeeIDs.includes(payslip.employeeID)) {
              this.employeeIDs.push(payslip.employeeID)
            }
          })
          if (this.employeeIDs.length > 0) {
            return this.getEmployees()
          } else {
            return true
          }
        }).then(() => {
          console.log(this.payslips)
          // order payslips by employee fullName
          this.payslips.sort((a, b) => {
            if (a.user.fullName < b.user.fullName) {
              return -1
            }
            if (a.user.fullName > b.user.fullName) {
              return 1
            }
            return 0
          })
          this.loading = false
        }).catch((error) => {
          console.log(error)
          this.loading = false
        })
    },
    async getEmployees() {
      // do only if employeeIDs is not empty
      if (this.employeeIDs.length === 0) {
        return
      }

      // Split employeeIDs into chunks of 10 or fewer elements
      const chunkSize = 10
      const employeeIdChunks = []
      for (let i = 0; i < this.employeeIDs.length; i += chunkSize) {
        employeeIdChunks.push(this.employeeIDs.slice(i, i + chunkSize))
      }

      // Fetch employees from Firestore for each chunk
      const employeePromises = employeeIdChunks.map((employeeIdChunk) =>
        firestore
          .collection('users')
          .where('employeeID', 'in', employeeIdChunk)
          .get(),
      )

      const querySnapshots = await Promise.all(employeePromises)

      querySnapshots.forEach((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const user = {
            id: doc.id,
            fullName: doc.data().fullName,
            department: doc.data().department,
            employeeID: doc.data().employeeID,
            imageUrl: doc.data().imageUrl,
          }
          this.users.push(user)
        })
      })

      // go through payslips and add employee object to each payslip
      this.payslips.forEach((payslip) => {
        payslip.user = this.users.find(
          (user) => user.employeeID === payslip.employeeID,
        )
        payslip.title = `${payslip.employeeID} - ${payslip.user.fullName} (${payslip.user.department}) - ${moment(payslip.period).format('MMMM YYYY')}`
      })
    },
  },
  mounted() {
    // Fetch employees and departments from Firestore and update the respective arrays
    this.getEmployees()
    this.getPayslips()
  },
}
</script>
